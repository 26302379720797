import { useContext } from "react";
import { ApolloProvider } from "@apollo/client";
import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/material/CssBaseline";
// Temporarily add @mui/material styling to use X Data Grid until it's available for Joy UI.
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { RequiredAuthProvider, RedirectToLogin, withAuthInfo, WithAuthInfoProps } from "@propelauth/react";
import { datadogRum } from "@datadog/browser-rum";
import { apolloClient, tokenContext } from "./graphql/apolloClient";
import { Loader } from "./components/loader";
import { NavBar } from "./components/navbar";
import { useConfig } from "./hooks/useConfig";
import { Pages } from "./pages";
import { dataDogInit } from "./util/dataDogRum";
import { joyTheme, materialTheme } from "./theme";

dataDogInit();

const App = withAuthInfo((props: WithAuthInfoProps) => {
  // accessToken and isLoggedIn are injected automatically from withAuthInfo
  const { setToken } = useContext(tokenContext);

  const { accessToken, isLoggedIn, user } = props;
  if (isLoggedIn) {
    setToken(accessToken);
    datadogRum.setUser({
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
    });
    return (props as any).children;
  }
  datadogRum.clearUser();
  return null;
});

export default function Root() {
  const config = useConfig();

  // ToDo: implement dark theme to support defaultMode="system"
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider theme={joyTheme} >
        <CssBaseline enableColorScheme />
          <RequiredAuthProvider
            authUrl={config.REACT_APP_PROPELAUTH_URL as string}
            displayWhileLoading={<Loader />}
            displayIfLoggedOut={<RedirectToLogin postLoginRedirectUrl={window.location.href} />}
          >
            <ApolloProvider client={apolloClient}>
              <App>
                <NavBar />
                <Pages />
              </App>
            </ApolloProvider>
          </RequiredAuthProvider>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}
