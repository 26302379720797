import { FetchPolicy, gql, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { UserFragment } from "./fragment/userFragments";
import { User } from "../models/user";

export const GET_USER = gql`
  query($id: Int) {
    user(id: $id) {
      ...userFragment
    }
  }
  ${UserFragment}
`;

export function useUser(
  id = null,
  fetchPolicy: FetchPolicy = "cache-first",
) {
  const { data, error, loading, refetch } = useQuery<{ user: User | null }>(GET_USER, {
    variables: { id },
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
  });

  const user = useMemo(() => data?.user
    ?? { configuration: {} } as unknown as User, [data]);

  return { user, error, loading, refetch };
}
