import { Route, Routes } from "react-router-dom";
import { Billing } from "./billing";
import { Blueprint } from "./blueprint";
import { AI } from "./ai";
import { Home } from "./home";
import { Profile } from "./profile";
import { routes } from "./routes";
import { ImageGallery } from "../components/imageGallery";
import { BlueprintList } from "../components/blueprintList";

export function Pages() {
  return (
    <div style={routeContainerStyle}>
      <Routes>
        <Route path={routes.aiGenerate.route} element={<AI />} />
        <Route
          path={routes.aiImages.route}
          element={<ImageGallery title="Generated Furniture" generator="AI" />}
        />
        <Route
          path={routes.aiImagesFav.route}
          element={
            <ImageGallery
              title="Favorite Furniture"
              generator="AI"
              favorite={true}
            />
          }
        />
        <Route path={routes.blueprintList.route} element={<BlueprintList title="Blueprint List" />} />
        <Route path={routes.billing.route} element={<Billing title="Subscription"/>} />
        <Route path={`${routes.blueprintOrder.route}/:id`} element={<Blueprint create={true} />} />
        <Route path={`${routes.blueprint.route}/:id`} element={<Blueprint />} />
        <Route path={routes.home.route} element={<Home />} />
        <Route path={routes.profile.route} element={<Profile />} />
      </Routes>
    </div>
  );
}

const routeContainerStyle = {
  margin: "4rem 1rem 1rem 1rem",
};
