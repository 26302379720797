import { gql } from "@apollo/client";

export const UserFragment = gql`
  fragment userFragment on User {
    id
    configuration {
      id
      aiModels {
        height
        label
        name
        prompt
        negativePrompt
        width
      }
      subscription {
        endDate
        nextPaymentDate
        plan
      }
    }
    createdAt
    email
    firstName
    lastName
    organization {
      id
      name
    }
    personas
    remainingBlueprintCredits
    role
  }
`;
